@import url("https://unpkg.com/leaflet@1.6.0/dist/leaflet.css");

.leaflet-container {
  width: 100%;
  height: 100vh;
}

.leaflet-popup {
  margin-bottom: 28px;
}

.leaflet-div-icon {
  background: transparent;
}

.prevet-map-container {
  margin: -16px 0px -18px -11px;
  border-bottom-left-radius: 7px;
}

@media (max-device-width: 768px) {
  .leaflet-container {
    width: 100%;
    height: 40vh;
  }

  .prevet-map-container {
    margin: 0px;
    border-bottom-left-radius: 0px;
  }
}
