@import "../../assets/scss/variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

$klaveness-orange: #ff5c29;

.Container {
  color: #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Background {
  &_Prevet {
    background: linear-gradient(0deg, rgba(0,20,70,1) 0%, rgba(0,20,70,0.65) 70%, rgba(0,20,70,0.5) 100%), url("./assets/desktop-prevetting.jpeg");
    background-size: cover;
  }
  
  &_PortPredictor {
    background: linear-gradient(0deg, rgba(0,20,70,1) 0%, rgba(0,20,70,0.65) 70%, rgba(0,20,70,0.5) 100%), url("./assets/desktop-port_predictor.jpeg");
    background-size: cover;
  }

  &_FreightOptimizer {
    background: linear-gradient(0deg, rgba(0,20,70,1) 0%, rgba(0,20,70,0.65) 70%, rgba(0,20,70,0.5) 100%), url("./assets/desktop-freight_optimizer.jpeg");
    background-size: cover;
  }

  &_CharteringInsights {
    background: linear-gradient(0deg, rgba(0,20,70,1) 0%, rgba(0,20,70,0.65) 70%, rgba(0,20,70,0.5) 100%), url("./assets/desktop-chartering_insights.jpeg");
    background-size: cover;
  }
  &_ReportsOverview {
    background: linear-gradient(0deg, rgba(0,20,70,1) 0%, rgba(0,20,70,0.65) 70%, rgba(0,20,70,0.5) 100%), url("./assets/desktop-reports_overview.jpeg");
    background-size: cover;
  }
}


.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1.5;
  padding: 2rem;

  &_Inner {
    padding-left: 1rem;
    border-left: 3px solid $klaveness-orange;
  }
}

.Body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex:4;
  padding: 1rem 2rem;
}

.ButtonContainer {
  flex:1.5;
  display: flex;
  justify-content: center;
}

.Button {
  height: 2.5rem;
  background-color: $klaveness-orange;
  display: flex;
  align-items: center;
}

.Title, .Subtitle {
  text-transform: uppercase;
}

.Title {
  font-size: 1.5rem;
  font-weight: 700;
  @include media-breakpoint-up(xl) {
    font-size: 2.2rem;
  }
}

.Subtitle {
  font-size: 1rem;
  @include media-breakpoint-up(xl) {
    font-size: 1.8rem;
  }
}


.Bullet {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  &::marker {
    color: $klaveness-orange;
  }
}