// Variables
$mobile-breakpoint: 768px;
$tablet-breakpoint: 1024px;
$desktop-breakpoint: 1440px;
$gap-size: 10px;

// Mixins for breakpoints
@mixin mobile {
  @media screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mobile-breakpoint + 1px}) and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin small-desktop {
  @media screen and (min-width: #{$tablet-breakpoint + 1px}) and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: #{$desktop-breakpoint + 1px}) {
    @content;
  }
}

// Card container styles
.PrevetCards {
  display: grid;
  gap: $gap-size;
  width: 100%;

  // Responsive layouts
  @include mobile {
    grid-template-columns: 1fr;
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include small-desktop {
    grid-template-columns: repeat(3, 1fr);
  }

  @include large-desktop {
    grid-template-columns: repeat(4, 1fr);
  }

  &_CircVesselForm {
    font-size: 0.7rem;
    padding: 5px;
    height: 25px;
    min-width: 25px;
    background-color: #f5f5f5;
  }

  &_CircVesselText {
    font-size: 0.7rem;

    &_SubText {
      position: absolute;
      font-size: 0.6rem;
      color: gray;
      top: -9px;
      left: 8px;
      flex-wrap: nowrap;

      &_SeaDays {
        top: -12px;
        font-size: 0.6rem;
        position: absolute;
        color: gray;
        flex-wrap: nowrap;
        left: 5px;
        white-space: nowrap;
      }
    }
  }
}

.PrevetMainContainer {
  max-width: 100vw;
  &_Collapsed {
    @media (min-width: 761px) {
      max-width: calc(100vw - 70px);
    }
  }
  &_UnCollapsed{
    @media (min-width:761px){
      max-width: calc(100vw - 240px);
    }
  }
}

.PrevetVesselTradingModal {
  width: 100vw;
  max-height: 90vh;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_Row {
    display: flex;
    height: 100%;
  }

  &_Map {
    flex: 4;
  }

  &_Info {
    background-color: white;
    flex: 1;
    padding: 1rem;
    z-index: 1000;
    position: absolute;
    width: 25%;
    right: 0;
    top: 55px;
  }
}

.dash {
  width: 18px;
  height: 4px;
  border-radius: 0%;
  margin-right: 8px;
  display: inline-block;
}

.circle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.hide-on-small-screen {
  @media (max-width: 1880px) {
    display: none;
  }
  @media (max-width: 1660px) {
    display: block;
  }
  @media (max-width: 1560px) {
    display: none;
  }
  @media (max-width: 760px) {
    display: block;
  }
}
.DatePickerText {
  width: 100%;
  padding-left: 70px;
}

.PrevetContainer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-gap: 12px;
  &_SearchWidget {
    grid-area: a;
  }
  &_ProlongedStayWidget {
    grid-area: b;
  }
  &_DDWidget {
    grid-area: c;
  }
  &_FiveCargoes {
    grid-area: d;
  }
  &_PortAlerts {
    grid-area: e;
  }
  &_RightShip {
    grid-area: f;
  }
  &_SpeedGraph {
    grid-area: g;
  }
  &_PortStaysGraph {
    grid-area: h;
  }
  &_PerformanceExposure {
    grid-area: i;
  }
  &_VesselInfo {
    grid-area: j;
  }
}

@media (min-width: 2101px) {
  .PrevetContainer {
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas:
      "a a a b b c c d e f"
      "g g g g g h h h h h"
      "g g g g g i i i j j";
  }
}

@media (max-width: 2100px) {
  .PrevetContainer {
    grid-template-areas:
      "a a a a a a b b b c c c c d d e e f f f"
      "g g g g g g g g g g h h h h h h h h h h"
      "g g g g g g g g g g i i i i i j j j j j";
  }
}

@media (max-width: 1712px) {
  .PrevetContainer {
    grid-template-areas:
      "a a a a a a a b b b b c c c c d d e e f f f"
      "g g g g g g g g g g g h h h h h h h h h h h"
      "g g g g g g g g g g g i i i i i j j j j j j";
  }
}

@media (max-width: 1560px) {
  .PrevetContainer {
    grid-template-areas:
      "a a a a a a a a b b b c c c c c"
      "g g g g g g g g d d e e f f f f"
      "g g g g g g g g h h h h h h h h"
      "g g g g g g g g h h h h h h h h"
      "g g g g g g g g h h h h h h h h"
      "g g g g g g g g h h h h h h h h"
      "i i i i i i i i j j j j j j j j";
  }
}

@media (max-width: 1024px) {
  .PrevetContainer {
    grid-template-areas:
      "a a"
      "b b"
      "c c"
      "d e"
      "f f"
      "g g"
      "h h"
      "i i"
      "j j";
  }
}
