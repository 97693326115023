//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-header {
  background-color: $white !important;
  border-bottom: $border-width solid $border-color;
}

.card-footer {
  background-color: $white !important;
}

.card-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

//Card Background Colors
.bg-primary.card {
  --bs-bg-opacity: 1;
  background-color: $k-dark-blue !important;
}

.bg-secondary.card {
  --bs-bg-opacity: 1;
  background-color: $k-gray-50 !important;
}

.bg-success.card {
  --bs-bg-opacity: 1;
  background-color: $success !important;
}

.bg-info.card {
  --bs-bg-opacity: 1;
  background-color: $k-light-blue !important;
}

.bg-warning.card {
  --bs-bg-opacity: 1;
  background-color: $warning !important;
}

.bg-danger.card {
  --bs-bg-opacity: 1;
  background-color: $danger !important;
}

.bg-dark-blue.card {
  --bs-bg-opacity: 1;
  background-color: $k-blue !important;
}
