
.mdi, .btn, .breadcrumb, .navbar-header, .mm-sidebar {
  @media print {
    display: none;
  }
}

.page-content, .page-title-box {
  @media print {
    margin-top: 1rem;
    padding: 0;
  }
}