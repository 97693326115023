//
// _footer.scss
//

.footer {
  display: flex;
  padding: 0.5rem;
  background-color: $footer-bg;
  min-height: 75px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-up(xl) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  
  &_credit {
    display: flex;
    align-self: flex-end;
    margin-left: 0.75rem;
    @include media-breakpoint-up(xl) {
      align-self: unset;
      margin-left: unset;
    }
  }
}

