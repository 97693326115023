.ReportsViewPdfModal {
  .modal-content {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  &_Body {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.ReportsListTable {
  &_Summary {
    &.wmde-markdown {
      font-size: 14px;
      background-color: transparent;
      color: black;
    }
  }
}

.AdminReportsUploadFormFormik {
  &_Name {
    border-color: $gray-400;
    width: 50%;
    margin: 0;
    &.is-invalid {
      border-color: $red; 
    }
  }
  &_MDEditor {
    &.w-md-editor {
      &.is-invalid {
        border: 1px solid $red;
        box-shadow: 0 0 0 0.05rem rgba(236, 69, 97, 0.25);
      }
    }
  }

  &_DropZoneContainer {
    &.is-invalid {
      .dropzone {
        border: 2px dashed $red;
        border-radius: 6px;
      }
    }
  }

  &_Error {
    color: $red;
    font-size: 12px;
  }
}




