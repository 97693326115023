//
// _header.scss
//

#page-topbar {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  background-color: $header-bg;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;

  .dropdown {
    .header-item.show {
      background: rgba($white, 0.05);
    }
  }
}

@media (max-width: 992px) {
  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}


.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 32px;
  width: 32px;
  background-color: rgba($white, 0.25);
}

.noti-icon {
  i {
    font-size: 24px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 17px;
    right: 7px;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: $gray-100;
  }
}

// Full Screen
.fullscreen-enable {
  [data-bs-toggle="fullscreen"] {
    .mdi-fullscreen::before {
      content: "\F0294";
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

