//navbar-brand-box

.navbar {
  &-brand-box {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
      padding: 0 1.5rem;
      width: 240px;
      &--isCollapsed {
        width:unset;
      }
    }
  }
  &-header-inner {
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.ps-sidebar-root.mm-sidebar {
  position: absolute;
  background-color: #ffffff;
  z-index: 1000;
  height: calc(100vh - 75px);
  top: 75px;
  @include media-breakpoint-up(sm) {
    position:unset;
    display: block;
    position: sticky;
  }
  
  @media print {
    display: none;
  }

  // Nest here to override styles from library
  .ps-menu-button {
    padding-left: 15px;
  }
}

.ps-collapsed {
  display: none;
  @include media-breakpoint-up(sm) {
    display: unset;
  }
}

.ps-sidebar-container {
  &::-webkit-scrollbar{
    width: 6px;
    &-track{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }
    &-thumb {
      border-radius: 10px;
      background-color: #C7C7C7;
    }
  }
}

.ps-active {
  background-color: #f3f3f3;
}

