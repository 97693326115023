.PrevetRightShip {
  flex: 1;
  @media (max-width: 767px) {
    width: 100%;
  }
  &_Heading {
    font-size: 1rem;
  }
  &_Logo {
    width: 80px;
    left: -2px;
    position: relative;
    top: 5px;
  }
  &_Row {
    padding: 0.25rem 0.5rem;
  }

  &_Col {
    flex: 1;

    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(xxl) {
      flex-direction: column;
      align-items: flex-end;
      padding-right: 0.75rem;
    }
    &--border {
      border-left: 1px solid #333333;
      border-right: 1px solid #333333;
    }
  }

  &_Col_Inner {
    min-height: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_Score {
    font-size: 2rem;
    &--sm {
      font-size: 1rem;
    }
  }
  &_Subscript {
    font-size: 10px;
    &--bold {
      font-weight: 600;
    }
  }
  &_Evdi {
    font-size: 12px;

    line-height: 1rem;
  }
  &_GhgIcon {
    position: relative;
    &-Letter {
      position: absolute;
      font-size: 0.8rem;
      font-weight: 600;
      left: 7px;
      top: 1px;
    }
    &-Svg {
      height: 25px;
    }
  }
  &_Footnote {
    font-size: 8px;
    @include media-breakpoint-up(lg) {
      font-size: 10px;
      // text-align: end;
    }
  }
}
