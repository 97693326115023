.react-datepicker__input-container {
    position: unset;
    display: unset;
    width: unset;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 0.3rem 0.3rem;
  
    input {
      border: 0px;
      margin: 0px;
      outline: 0px;
      padding: 0px;
    }
  }