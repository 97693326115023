.accordion-button {
  padding: 1rem 0
}

.reports .accordion.accordion-flush .accordion-item {
  border-bottom: none !important;  
}

@media only screen and (min-width: 1600px) {
  /**
  * Stop the jumping of table rows when the accordion is opened. The table row cells
  * are dynamically sized depending on the width of the text content unless width is 
  * enforced. Only do this for large screens as its not possible to size for all
  * screen sizes.
  * External monitors
  */
  .reports .accordion-item:last-of-type .accordion-collapse {
    width: 80vw;
  }
}

@media only screen and (min-width: 1440px) {
  /**
  * Laptop screens
  */
  .reports .accordion-item:last-of-type .accordion-collapse {
    width: 70vw;
  }
}