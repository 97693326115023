.AdminReportsUploadBar {
  &_Title {
    margin-left: 0.25rem;
    margin-right: 1rem
  }
  &_Select_Wrapper {
    margin-right: 1rem;
  }
  &_Select {
    width: 230px;
  }
}

.AdminReportsUploadEditModal {
  .modal-content {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
  @include media-breakpoint-up(lg)  {
    & .modal-dialog {
      max-width: 900px;
    }
  }
}

.AdminReportsDropZone {
  &_Content {
    display: flex;
    justify-content: center;
  }
  &_Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}