
.PrevetDryDockBadge {
  padding: 2px 16px;
  @media print {
    margin: 2rem 0;
  }

  &--warning {
    border: 2px solid #ffdd15;
    background-color: #ffdd15;
    color: #000000 
  }
  &--ok {
    border: 2px solid #00763b;
    background-color: #00763b;
    color: #ffffff;
    @media print {
      color: #000000; 
    }
  }
  &--danger {
    background-color: #ec4561;
    border: 2px solid #ec4561;
    color: #ffffff;
    @media print {
      color: #000000; 
    }
  }
  &--default {
    background-color: #ffffff;
    color: #000000; 
  }
}