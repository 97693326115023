.PrevetSensitivity {
  &_Label {
    font-size: 0.8rem;
    &--heading {
      min-width: 59px;
      display: block;
    }
  }

  & .flex-0 {
    flex: 0;
  }

  &_PerformanceExposure {
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &_Icon {
    height: 18px;
    top: 5px;
    left: 5px;
    position: relative;
    &--down {
      transform: rotate(90deg);
    }
    &--up {
      transform: rotate(-90deg);
    }
  }

  // Hide the up and down arrows on the type="number" input type
  & input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &_TCDescGroup {
    margin-top: 3.6rem;
    &--bottom {
      margin-top: 5rem;
    }
  }
  &_Input {
    width: 100%;
    margin: 0;
    position: relative;
    border-radius: 0.25rem !important;
    font-weight: 600;
    &--large {
      flex: 2.2;
    }
    &--dummy {
      flex: 0.5;
    }
  }

  &_Unit {
    right: 1.1rem;
    position: relative;
    z-index: 999;
    opacity: 0.6;
  }

  &_UnitAbs {
    position: absolute;
    z-index: 999;
    top: 20%;
    right: 10%;
    opacity: 0.6;
  }

  &_FloatTitle {
    position: absolute;
    top: -1.6rem;
    z-index: 999;

    &--bottom {
      position: absolute;
      padding-top: 2px;
      z-index: 999;
      min-width: 200px;
      font-weight: 600;
    }
  }

  &_StandardVesselBox {
    &--light {
      opacity: 0.5;
    }
    &--dark {
      opacity: 1;
    }
    border: 1px solid #ced4da;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    min-width: 100px;
    padding: 0.9rem 0.75rem 0.5rem;
    margin: 1rem 0;
  }

  &_StandardVesselTitle {
    position: absolute;
    top: -1rem;
    background-color: #ffffff;
    padding: 5px;
    text-decoration: underline;
    text-decoration-color: #ced4da;
  }

  &_ResetButton {
    padding: 0 0.75rem;
    height: 30px;
  }

  &_DollarSign{
    position:absolute;
    top:20%;
    left: 10px;
    pointer-events: none;
    color: #6c757d;
  }
}
