.eye {
    background: white;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 50%;
    left: 10%; 
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 75% 0;
    overflow: hidden;
    margin-left: 10px;
}

.ball {
    width: 3px;
    height: 3px;
    background: #d9e6f6;
    border-radius: 50%;
    border: 3px solid rgb(1, 2, 50);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.shut {
    width: 11px;
    height: 8px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: 999;
}

.shut span {
    display: block;
    width: 100%;
    height: 100%; /* Initially cover the eye */
    background: white;
    border-radius: 0 0 60% 60%;
    transition: 0.4s all;
}

.eye-button:hover .eye .shut span {
    height: 15%; /* Open the eye on hover */
}

.eye-button {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 40px; 
}
