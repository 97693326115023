.Sidebar {
  &_SectionTitle {
    font-size: 0.75rem;
    padding: 1rem 0 0 1.5rem;
  }
  &_LevelTwoMenuItemInner {
    padding-left: 2.75rem;
  }
  &_LevelTwoSubMenu {
    .ps-menu-label {
      padding-left: 2.75rem;
    }
  }
  &_LevelThree {
    padding-left: 0.5rem;
  }
}

