.PoolPortal {
  &_BreadcrumbBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_AdminHeader {}

  &_Search {
    width: 75%;
    display: flex;

  }

  &_SearchControl {
    width: 75%;
  }

  &_SettingsControl {
    justify-content: end;
  }

  &_FilterInput {
    width: 100%;
    z-index: 0;
  }

  &_ClearButton {}

  &_UploadButton {
    display: flex;
  }

  &_ActionButtons {
    margin-right: 2px;
  }

  &_OffcanvasWrapper {}

  &_SettingsOffcanvas.offcanvas {
    width: 65%;
    top: 70px;
    bottom: 30px;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px 0 0 5px;
  }

  &_SettingsEditOffcanvas.offcanvas {
    width: 50%;
    top: 70px;
    bottom: 30px;
    box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px 0 0 5px;
  }

  &_ErrorMessage {
    color: red;
  }

  &_AccordionBody,
  &_AdminEntryBody {
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 5px 30px;
  }

  .modal-content {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  &_Body {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }

  &_DataCell {
    font-size: 12px;
    width: 100px;
    max-width: 100px;
    overflow: auto;
  }

  &_AdminTableContainer {
    width: 1080px;
    overflow-x: auto;

    & thead th {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &_UserContainer {
    display: flex;
    flex-direction: column;
  }

  &_UserFilter {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: white;
    border-radius: 2px;
  }

  &_UserFilterRow {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  &_UserFilterLabel {
    padding-right: 10px;
    width: 20%;
    text-align: right;

    & h5 {
      padding: 10px 20px 0 20px;
    }
  }

  &_LoadingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;

    & span {
      padding-left: 10px;
      align-items: center;
    }
  }

  &_SortedGroupContainer {
    padding: 25px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 2px;
  }

  &_DocGroupCol {
    padding: 10px 10px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_DocGroupLabel {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    & h5 {
      padding-left: 20px;
      padding-top: 5px;
    }
  }

}

.SettingsEditOffCanvassBackDrop {
  --bs-backdrop-opacity: 0.50;
  --bs-backdrop-zindex: 4059;
  z-index: 4059;
}

.SettingsDeleteConfirmationBackDrop {
  --bs-backdrop-opacity: 0.50;
  --bs-backdrop-zindex: 5059;
  z-index: 5059;
}

.MainAdminConfirmationBackDrop {
  --bs-backdrop-opacity: 0.50;
  --bs-backdrop-zindex: 4059;
  z-index: 4059;
}

.AdminSettingOverlayBackDrop {
  --bs-backdrop-opacity: 0.50;
  --bs-backdrop-zindex: 3059;
  z-index: 3059;
}

.DocumentViewDialog {
  width: 900px;
  max-width: none;
}