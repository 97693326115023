//Form Control Modifications

label {
  width: 20%;
  margin: 0px 5px;
}

.form-control {
  display: inline-block;
  width: 75%;
  border: 1px solid $border-color;
  margin: 0px 5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $k-gray-30;
}

.invalid-feedback {
  display: block;
  width: 75%;
  margin-left: 25%;
}

.special-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  width: 20%;
  margin: 0px 5px;
}

@media (max-device-width: 768px) {
  .form-control {
    width: 100%;
    margin: 0px;
  }

  .invalid-feedback {
    display: block;
    width: 100%;
  }

  .special-label {
    display: inline-block;
    margin-bottom: 0.5rem;
    width: 100%;
    margin: 0px;
  }
}
