.PortPredictor_CheckBoxSelector {
  &_ItemWrapper {
    min-width: 70px;
  }
  &_Item {
    @include media-breakpoint-up(lg) {
      align-items: end;
    }
    display: flex;
    flex-direction: column;
  }
  &_CheckInput {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  &_Label {
    font-size: 0.75rem;
  }
}
