.VesselSearch {
  &_Row {
    @include media-breakpoint-up(lg) {
      align-items: center;
      flex-direction: row;
    }
    justify-content: space-between;
    position: relative;
    z-index: 999;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  &_Col {
    flex: 1;
    &--left {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid #9b9b9b;
        padding-right: 1rem;
      }
    }
    &--right {
      margin-top: 1rem;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding-left: 1rem;
      }
    }
  }

  &_SearchVesselInputs {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  &_Title {
    flex: 3;
  }

  &_Button {
    flex: 1;
    @include media-breakpoint-up(lg) {
      min-width: 200px;
      max-width: 200px;
    }
  }

  &_Label {
    @include media-breakpoint-up(lg) {
      min-width: 110px;
      display: flex;
      align-items: center;
      flex: 1;
      width: unset;
    }
    width: 100%;
  }

  &_Name {
    @include media-breakpoint-up(lg) {
      flex: 3;
      margin-right: 1rem;
      width: unset;
    }
    width: 100%;
  }

  &_Segment {
    @include media-breakpoint-up(lg) {
      flex: 1;
      min-width: 200px;
      max-width: 200px;
    }
    width: 100%;
  }

  &_ButtonContainer {
    @include media-breakpoint-up(lg) {
      flex: 1;
      width: unset;
    }
    width: 100%;
    .btn {
      min-height: 38px;
    }
  }
}
