//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

button.float-right {
  float: right;
}

button.float-left {
  float: left;
}

.btn {
  border-radius: 5px;
  padding: 0.35rem 1rem;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark {
  color: $gray-200 !important;
}

.btn-link {
  color: $gray-200 !important;
}

.btn-outline-dark {
  &:hover {
    color: $gray-200 !important;
  }
}
