#layout-wrapper {
  position: relative;
}

.app-wrapper {
  overflow-x: clip;
  display: flex;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  min-height: calc(100vh - 75px);

  &_inner {
    padding: 1rem;
  }

  @media (min-width: 992px) {
    width: unset;
    // min-height: unset;
  }
  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}
