.powerbi-report-style {
  min-height: 90vh;
  width: auto;
  padding: 15px 15px 0px 15px;
  margin-top: 15px;
  iframe {
    min-height: 90vh;
    height: auto;
    width: 100%;
  }
}
