/*
Market Manager Platform Template
Author: Abel Chua
Version: 1.0.0
Contact: ach@klaveness.com
File: Custom Bootstrap Css File
*/

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";

@import "custom/components/reboot";
@import "custom/components/card";

@import "custom/components/nav";
@import "custom/components/table";

@import "custom/components/accordion";
@import "custom/components/buttons";
@import "custom/components/breadcrumb";
@import "custom/components/dropdown";

.modal-dialog {
  margin-top: 5rem;
}

.popover-body {
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
}

.nav-tabs {
  --bs-nav-tabs-link-active-bg: #fff;
}

.alert-info {
  --bs-alert-color: #284183;
  --bs-alert-bg: #d9e2f8;
  --bs-alert-border-color: #c6d3f4;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}
