.PrevetTrio {
  display: flex;
  flex-direction: column;
  gap: 0.8em;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  @media print {
    flex-direction: row;
  }

  &_ShipNameImo {
    display: none;

    @media print {
      display: block;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }

  &_PortStays {
    flex: 1;

    @include media-breakpoint-up(xxl) {
      flex: 1;
    }

    @media print {
      width: 100%;
      flex: 1;
    }
  }

  &_SpeedGraph {
    background-color: #ffffff;
    flex: 1;

    &_OceanZoneButton {
      padding: 0 0.75rem;
      height: 2.875rem !important;
      min-width: 80px !important; 
      width: 80px !important; 
      flex-shrink: 0;
      white-space: wrap;
      
      @include media-breakpoint-up(xl) { // For xl and larger screens see variable.scss
        min-width: 160px !important;
        width: 160px !important;
        white-space: nowrap;
        height: 1.875rem !important;
      }
    }

    &_OceanZoneButton_VesselSpeed {
      @extend .PrevetTrio_SpeedGraph_OceanZoneButton;
    }

    &_Inner {
      padding: 1.25rem 1.25rem 0;
    }

    @include media-breakpoint-up(xxl) {
      flex: 1;
    }
  }

  &_Sensitivity {
    flex: 1.05;
  }
}