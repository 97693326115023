.PrevetVesselHistory_Pagination {
  @media print {
    opacity: 0;
    border: 2px solid $green;
  }
}
.PrevetVesselHistory {
  &_Row {
    &--danger {
      background: #ec4561;
      color: #ffffff;
      td {
        color: #ffffff;
      }
    }
    &--danger {
      @media print {
        outline: 2px solid #ec4561;
      }
    }
    &--warning {
      background: $light-yellow;
      border: 2px solid $light-yellow;
    }
    &--success {
      background: $green;
      color: #ffffff;
      border: 2px solid $green;
    }
  }
  &_Header {
    @media print {
      display: none;
    }
  }
  &_Form {
    flex: 0 1 auto;
    display: flex;
    gap: 1rem;
    align-items: center;

    .form-check {
      transform: none !important; // Prevent any rotation

      .form-check-label {
        transform: none !important;
        writing-mode: horizontal-tb !important; // Force horizontal text
        text-orientation: mixed !important;
      }
    }
  }
  &_DateCell {
    min-width: 80px;
  }
  &_FilterInput {
    flex: 0 1 auto;
    max-width: 200px;
    margin-left: 1rem;
  }
  &_ClearButton {
    display: none;
  }
  &_Pagination {
    & .paginationOpts__root {
      flex: 1;
      display: flex;
      max-width: 200px;
      align-items: center;

      & label {
        flex: 2;
      }
      & select {
        height: 40px;
        flex: 1;
      }
    }

    & .ButtonGroup__root {
      flex: 1;
      max-width: 400px;
      max-height: 40px;
      margin-top: 1rem;
    }
    @include media-breakpoint-up(md) {
      & .ButtonGroup__root {
        margin-top: 0;
      }
    }
  }
}
