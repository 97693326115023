@import "./PrevetRightShip";
@import "./PrevetDryDockBadge";
@import "./PrevetVesselHistory";
@import "./PrevetTrio";
@import "./PrevetWrapperBreadcrumbBar";
@import "./PrevetVesselTradingModal";
@import "./PrevetSensitivityCalculator";
@import "./PrevetCards";

.PrevetContainer {
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    display: none;
  }
  .react-datepicker__input-container {
    padding: 0;
  }
}

.SensitivityBenchmark {
  &_Wrapper {
    border-radius: 5px;
    background: #f8f9fa;
    margin-bottom: 1rem;
    padding: 1rem 1rem 0.75rem;
  }
  &_Data {
    line-height: 0.9rem;
  }
}

.PrevetSensitivity {
  &_SeaDaysInput {
    display: flex;

    padding-left: 0.5rem;
    margin-bottom: 0.4rem;
    align-items: center;
    margin-top: 1.6rem;

    &_Label {
      flex: 1;
      margin: 0.75rem 0.3rem;
    }

    &_Control {
      flex: 2;
    }
  }
}

.PrevetSensitivityCalculator_Table {
  .tbody-tr {
    height: 55px;

    .tbody-td {
      vertical-align: middle;
    }
  }
}

.PrevetMain {
  &_SearchBar {
    @media print {
      display: none;
    }
  }
}

.BottomCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  @media (max-width: 1660px) {
    flex-direction: column;
  }
}

@media print {
  @page {
    size: A3 landscape;
    margin: 10mm;
  }

  body {
    width: 1880px;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .PrevetContainer {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas:
      "a a a b b c c d e f"
      "g g g g g h h h h h"
      "g g g g g i i i j j";
    grid-gap: 10px;
    width: 100%;
    max-height: 90vh;
    /* transform: scale(0.9);  */
    transform-origin: top left;
    margin: 0;
    padding: 0;
  }

  /* .PrevetContainer > * {
    page-break-after:always;
  } */

  .PrevetVesselTradingModal,
  .hide-on-small-screen,
  .DatePickerText {
    display: none;
  }

  .PrevetChosenVesselData_Row {
    page-break-before: always;
  }
}

.card {
  margin-bottom: 0px;
}
