.sortable-item-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 768px){
    .sortable-item-div{
        width: 75%
    }
}

.sortable-item-button{
    border: none;
    background-color: transparent;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    height: 36px;
}

.drag-button:hover {
    background-color: #E3E3E3;
    cursor: grab;
}

.drag-button:active{
    cursor: grabbing;
}

.drag-icon{
    height: 20px;
    color: #c7c5c5;
}

.sortable-button-common:hover{
    background-color: #E3E3E3;
}

.arrow-icon{
    height: 15px;
    color:#919190;
    transition: transform 0.3s ease;
}

.rotate {
    transform: rotate(90deg);
}

.options-icon{
    height: 15px;
    color:#919190;
}
