@import "../../../assets/scss/variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

.Map {
  :global {
    .table-striped > tbody > tr:nth-of-type(odd) > * {
      font-size: 12px;
    }

    .table > :not(caption) > * > * {
      padding: 0.25rem;
    }

    .accordion-button {
      padding: 0.25rem 0;
    }

    .accordion .accordion-item {
      border-bottom: 0 !important;
    }
  }
}

.PortStaysAccordion {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 10px;
    z-index: 1000;
    border-radius: 3px;
    background-color: #ffffff;
    min-width: 300px;
    padding: 0.2rem 0.5rem 0;
  }
}

.Legend {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  border-radius: 3px;
  background-color: #ffffff;
  min-width: 150px;
  padding: 0.2rem 0.5rem 0;

  &_Icon {
    width: 15px;
    height: 15px;
  }

  &_FutureLine {
    width: 25px;
    border: 2px dashed #7bf712;
  }

  &_Line {
    width: 25px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: -3px;
      transform: translateX(-50%);
      width: 7px; /* Size of the dot */
      height: 7px;
      border-radius: 50%;
    }

    &_Ballast {
      border: 1px solid #ff5c29;
      &::before {
        background-color: #ff5c29; /* Color of the dot */
      }
    }
    &_Laden {
      border: 1px solid #99b0ff;
      &::before {
        background-color: #99b0ff; /* Color of the dot */
      }
    }
  }

  &_CurrentPos {
    border-radius: 50%;
    background-color: aqua;
  }

  &_PriorPort {
    background-color: #999;
  }
  &_FuturePort {
    background-color: #2a82cb;
  }
}
